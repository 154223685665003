exports.components = {
  "component---src-pages-fashion-underwear-js": () => import("./../../../src/pages/fashion-underwear.js" /* webpackChunkName: "component---src-pages-fashion-underwear-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-packaging-js": () => import("./../../../src/pages/packaging.js" /* webpackChunkName: "component---src-pages-packaging-js" */),
  "component---src-pages-placement-prints-js": () => import("./../../../src/pages/placement-prints.js" /* webpackChunkName: "component---src-pages-placement-prints-js" */),
  "component---src-pages-presentations-js": () => import("./../../../src/pages/presentations.js" /* webpackChunkName: "component---src-pages-presentations-js" */),
  "component---src-pages-printed-material-js": () => import("./../../../src/pages/printed-material.js" /* webpackChunkName: "component---src-pages-printed-material-js" */),
  "component---src-pages-prints-js": () => import("./../../../src/pages/prints.js" /* webpackChunkName: "component---src-pages-prints-js" */)
}

